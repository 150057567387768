import React, { useState } from 'react';
import axios from 'axios';
// import useScript from '../hooks/useScript';
// import { Link } from 'gatsby';
//  import JSONData from '../content/artgroup.json';

function DigitalPublication() {
  // useScript('/libs/mobile_script.js');

  const [books, setBooks] = useState(null);

  const fetchBallet = async () => {
    let response = await axios.get('/content/ballet-west.json');
    setBooks(response.data);
  };

  const fetchBroadway = async () => {
    let response = await axios.get('/content/broadway-at-the-eccles.json');
    setBooks(response.data);
  };

  const fetchBroadwayBoise = async () => {
    let response = await axios.get('/content/broadway-in-boise.json');
    setBooks(response.data);
  };

  const fetchDVMF = async () => {
    let response = await axios.get('/content/dvmf.json');
    setBooks(response.data);
  };

  const fetchGrand = async () => {
    let response = await axios.get('/content/grand-theatre.json');
    setBooks(response.data);
  };

  const fetchBestor = async () => {
    let response = await axios.get('/content/kurt-bestor.json');
    setBooks(response.data);
  };

  const fetchOdyssey = async () => {
    let response = await axios.get('/content/odyssey-dance-theatre.json');
    setBooks(response.data);
  };

  const fetchOgden = async () => {
    let response = await axios.get('/content/onstage-ogden.json');
    setBooks(response.data);
  };

  const fetchPioneer = async () => {
    let response = await axios.get('/content/pioneer-theatre-company.json');
    setBooks(response.data);
  };

  const fetchSLAC = async () => {
    let response = await axios.get('/content/salt-lake-acting-company.json');
    setBooks(response.data);
  };

  const fetchTuacahn = async () => {
    let response = await axios.get('/content/tuacahn.json');
    setBooks(response.data);
  };

  const fetchUAF = async () => {
    let response = await axios.get('/content/utah-arts-festival.json');
    setBooks(response.data);
  };

  const fetchUFO = async () => {
    let response = await axios.get('/content/utah-festival-opera.json');
    setBooks(response.data);
  };

  const fetchOpera = async () => {
    let response = await axios.get('/content/utah-opera.json');
    setBooks(response.data);
  };

  const fetchUtahPresents = async () => {
    let response = await axios.get('/content/utahpresents.json');
    setBooks(response.data);
  };

  const fetchBard = async () => {
    let response = await axios.get('/content/utah-shakespeare-festival.json');
    setBooks(response.data);
  };

  const fetchSymphony = async () => {
    let response = await axios.get('/content/utah-symphony.json');
    setBooks(response.data);
  };

  const fetchRealtor = async () => {
    let response = await axios.get('/content/realtor.json');
    setBooks(response.data);
  };

  const fetchDental = async () => {
    let response = await axios.get('/content/utah-dental.json');
    setBooks(response.data);
  };

  const fetchPhysician = async () => {
    let response = await axios.get('/content/utah-physician.json');
    setBooks(response.data);
  };

  const fetchFootball = async () => {
    let response = await axios.get('/content/utah-football.json');
    setBooks(response.data);
  };

  const fetchBasketball = async () => {
    let response = await axios.get('/content/utah-basketball.json');
    setBooks(response.data);
  };

  const fetchGymnastics = async () => {
    let response = await axios.get('/content/utah-gymnastics.json');
    setBooks(response.data);
  };

  return (
    <>
      <hr />
      <h3>Digital Flipbook Examples</h3>
      <h4>Arts</h4>
      <ul className="actions">
        <li className="bigButtons">
          <a
            href="#digipubs"
            className="button special small arts"
            onClick={fetchBallet}
          >
            Ballet West
          </a>
        </li>
        <li className="bigButtons">
          <a
            href="#digipubs"
            className="button special small arts"
            onClick={fetchBroadway}
          >
            Broadway at the Eccles
          </a>
        </li>
        <li className="bigButtons">
          <a
            href="#digipubs"
            className="button special small arts"
            onClick={fetchBroadwayBoise}
          >
            Broadway in Boise
          </a>
        </li>
        <li className="bigButtons">
          <a
            href="#digipubs"
            className="button special small arts"
            onClick={fetchDVMF}
          >
            Deer Valley® Music Festival
          </a>
        </li>
        <li className="bigButtons">
          <a
            href="#digipubs"
            className="button special small arts"
            onClick={fetchGrand}
          >
            Grand Theatre
          </a>
        </li>
        <li className="bigButtons">
          <a
            href="#digipubs"
            className="button special small arts"
            onClick={fetchBestor}
          >
            Kurt Bestor
          </a>
        </li>
        <li className="bigButtons">
          <a
            href="#digipubs"
            className="button special small arts"
            onClick={fetchOdyssey}
          >
            Odyssey Dance Theatre
          </a>
        </li>
        <li className="bigButtons">
          <a
            href="#digipubs"
            className="button special small arts"
            onClick={fetchOgden}
          >
            Onstage Ogden
          </a>
        </li>
        <li className="bigButtons">
          <a
            href="#digipubs"
            className="button special small arts"
            onClick={fetchPioneer}
          >
            Pioneer Theatre Company
          </a>
        </li>
        <li className="bigButtons">
          <a
            href="#digipubs"
            className="button special small arts"
            onClick={fetchSLAC}
          >
            Salt Lake Acting Company
          </a>
        </li>
        <li className="bigButtons">
          <a
            href="#digipubs"
            className="button special small arts"
            onClick={fetchTuacahn}
          >
            Tuacahn
          </a>
        </li>
        <li className="bigButtons">
          <a
            href="#digipubs"
            className="button special small arts"
            onClick={fetchUAF}
          >
            Utah Arts Festival
          </a>
        </li>
        <li className="bigButtons">
          <a
            href="#digipubs"
            className="button special small arts"
            onClick={fetchUFO}
          >
            Utah Festival Opera
          </a>
        </li>
        <li className="bigButtons">
          <a
            href="#digipubs"
            className="button special small arts"
            onClick={fetchOpera}
          >
            Utah Opera
          </a>
        </li>
        <li className="bigButtons">
          <a
            href="#digipubs"
            className="button special small arts"
            onClick={fetchUtahPresents}
          >
            UtahPresents
          </a>
        </li>
        <li className="bigButtons">
          <a
            href="#digipubs"
            className="button special small arts"
            onClick={fetchBard}
          >
            Utah Shakespeare Festival
          </a>
        </li>
        <li className="bigButtons">
          <a
            href="#digipubs"
            className="button special small arts"
            onClick={fetchSymphony}
          >
            Utah Symphony
          </a>
        </li>
      </ul>

      <h4>University Sports Programs</h4>
      <ul className="actions">
        <li className="bigButtons">
          <a
            href="#digipubs"
            className="button special small arts"
            onClick={fetchFootball}
          >
            Utah Football
          </a>
        </li>
        <li className="bigButtons">
          <a
            href="#digipubs"
            className="button special small arts"
            onClick={fetchBasketball}
          >
            Utah Basketball
          </a>
        </li>
        <li className="bigButtons">
          <a
            href="#digipubs"
            className="button special small arts"
            onClick={fetchGymnastics}
          >
            Utah Gymnastics
          </a>
        </li>
        <li className="bigButtons">
          <a
            href="https://issuu.com/millspublishing/stacks/41cfd1e2c8f24bedb14f6a4fb9911ea0"
            className="button special small arts"
          >
            Utah State Football
          </a>
        </li>
        <li className="bigButtons">
          <a
            href="https://issuu.com/millspublishing/stacks/f1038ed5a4a24085b90059e70c9289a7"
            className="button special small arts"
          >
            Utah State Basketball
          </a>
        </li>
      </ul>

      <h4>Professional Journals</h4>
      <ul className="actions">
        <li className="bigButtons">
          <a
            href="#digipubs"
            className="button special small arts"
            onClick={fetchRealtor}
          >
            Salt Lake Realtor®
          </a>
        </li>
        <li className="bigButtons">
          <a
            href="#digipubs"
            className="button special small arts"
            onClick={fetchDental}
          >
            Utah Dental Association
          </a>
        </li>
        <li className="bigButtons">
          <a
            href="#digipubs"
            className="button special small arts"
            onClick={fetchPhysician}
          >
            Utah Physician
          </a>
        </li>
      </ul>

      <h4>Outdoor Sports Guide Magazine</h4>
      <ul className="actions">
        <li className="bigButtons">
          <a
            href="https://issuu.com/sports.guide/docs"
            className="button special small arts"
          >
            Outdoor Sports Guide
          </a>
        </li>
      </ul>

      <hr />
      <div id="digipubs" className="grid-wrapper">
        {books &&
          books.issuu.map((book, index) => {
            return (
              <section key={index} className="col-6">
                <header className="major">
                  <h3>{book.title}</h3>
                </header>
                <p dangerouslySetInnerHTML={{ __html: book.desc }}></p>
                <ul className="actions">
                  <li className="bigButtons">
                    <a
                      href={book.stack}
                      className="button special"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      See all
                    </a>
                  </li>
                </ul>
              </section>
            );
          })}
      </div>
      <ul className="actions">
        <li className="bigButtons">
          <a href="#" className="button">
            <i className="icon fa-arrow-up"> </i>Return to Top
          </a>
        </li>
      </ul>
    </>
  );
}

export default DigitalPublication;
